import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

// External Actions
// import { setErrorMessage } from '../error/ErrorSlice';

// Helpers
import auth from '../../helpers/auth';

// Async Action
export const checkResponse = (response) => {
  return response
    .text()
    .then((text) => {
      const data = text && JSON.parse(text);
      if (!response.ok) {
        if (response.status === 401 || response.status === 403) {
          auth.clearUserInfo();
          window.location.href = '';
        }
        const error = (data && data.message) || response.statusText;
        return Promise.reject(error);
      }

      return data;
    })
    .catch((err) => Promise.reject(err));
};

// Async Action
export const authRegister = createAsyncThunk(
  'auth/register',
  async (params, { rejectWithValue }) => {
    const response = await fetch(
      `${process.env.REACT_APP_PROXY_URL}/integration/register`,
      {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        // This is a CORS request, so we need to include credentials
        mode: 'cors',
        credentials: 'include',
        body: JSON.stringify(params),
      }
    )
      .then((res) => res.json())
      .then((data) => {
        if (data.error) return rejectWithValue(data.message);
        return data;
      })
      .catch((error) => {
        console.log({ error });
        return rejectWithValue(error);
      });

    // Save the user in localStorage
    if (response.user) auth.setUserInfo(response.user, true);

    return response;
  }
);

// Async Action
export const authLogin = createAsyncThunk(
  'auth/login',
  async (credentials, { rejectWithValue }) => {
    const response = await fetch(
      `${process.env.REACT_APP_PROXY_URL}/integration/login`,
      {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        mode: 'cors',
        credentials: 'include',
        body: JSON.stringify(credentials),
      }
    )
      .then((res) => res.json())
      .then((data) => {
        if (data.error) return rejectWithValue(data.message);
        return data;
      });
    // .catch((error) => {
    //   console.log({ error });
    //   rejectWithValue(error);
    // });

    // Save the user in localStorage
    if (response.user) auth.setUserInfo(response.user, true);

    return response;
  }
);

// Async Action
export const authRecovery = createAsyncThunk(
  'auth/recovery',
  async (email, { rejectWithValue }) => {
    const response = await fetch(
      `${process.env.REACT_APP_PROXY_URL}/auth/forgot-password`,
      {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        mode: 'cors',
        body: JSON.stringify(email),
      }
    )
      .then((res) => res.json())
      .then((data) => {
        console.log({ data });
        if (data.error) return rejectWithValue(data);
        return data;
      })
      .catch((error) => {
        console.log({ error });
      });

    return response;
  }
);

// Async Action
export const authReset = createAsyncThunk(
  'auth/reset',
  async (params, { rejectWithValue }) => {
    const response = await fetch(
      `${process.env.REACT_APP_PROXY_URL}/auth/reset-password`,
      {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        mode: 'cors',
        body: JSON.stringify(params),
      }
    )
      .then((res) => res.json())
      .then((data) => {
        console.log({ data });
        if (data.error) return rejectWithValue(data);
        return data;
      })
      .catch((error) => {
        console.log({ error });
      });

    return response;
  }
);

// Async Action
export const getCurrentUser = createAsyncThunk(
  'auth/me',
  async (credentials, { rejectWithValue }) => {
    const response = await fetch(
      `${process.env.REACT_APP_PROXY_URL}/users/me`,
      {
        method: 'GET',
        headers: { 'Content-Type': 'application/json' },
        mode: 'cors',
        credentials: 'include',
        body: JSON.stringify(credentials),
      }
    )
      // .then((res) => res.json())
      .then(checkResponse)
      .then((data) => {
        if (data.error) return rejectWithValue(data);
        return data;
      });

    // Save the user in localStorage
    if (response.user) auth.setUserInfo(response.user, true);

    return response;
  }
);

const initialState = {
  status: null,
  token: null,
  user: null,
  isAuthenticated: false,
  error: [],
};

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    saveToken: (state, { payload }) => {
      if (payload) state.token = payload;
    },
    clearToken: (state) => {
      state.token = null;
    },
    saveUser: (state, { payload }) => {
      if (payload) state.user = payload;
    },
    clearUser: (state) => {
      state.user = null;
    },
    clearStatus: (state) => {
      state.status = null;
    },
    setAuthState: (state, { payload }) => {
      state.isAuthenticated = payload;
    },
  },
  extraReducers: {
    [authLogin.pending]: (state, action) => {
      state.status = 'loading';
      state.isAuthenticated = false;
    },
    [authLogin.fulfilled]: (state, action) => {
      state.status = 'succeeded';
      state.isAuthenticated = true;
      state.user = action.payload.user;
    },
    [authLogin.rejected]: (state, action) => {
      state.status = 'failed';
      state.isAuthenticated = false;
      if (action.payload) state.error = action.payload.data[0].messages;
      else state.error = action.error.message;
    },
    [authRegister.pending]: (state, action) => {
      state.status = 'loading';
      state.isAuthenticated = false;
    },
    [authRegister.fulfilled]: (state, action) => {
      state.status = 'succeeded';
      state.isAuthenticated = true;
      state.user = action.payload.user;
    },
    [authRegister.rejected]: (state, action) => {
      console.log({ action });
      state.status = 'failed';
      state.isAuthenticated = false;
      if (action.payload) state.error = action.payload[0].messages;
      else state.error = action.error.message;
    },
    [getCurrentUser.fulfilled]: (state, action) => {
      state.status = 'succeeded';
      state.isAuthenticated = true;
      state.user = action.payload;
    },
    // Recovery
    [authRecovery.pending]: (state, action) => {
      state.status = 'loading';
      state.isAuthenticated = false;
    },
    [authRecovery.fulfilled]: (state, action) => {
      state.status = 'succeeded';
      state.isAuthenticated = false;
      state.error = [{ id: 'Auth.recovery.ok' }];
    },
    [authRecovery.rejected]: (state, action) => {
      console.log(action.payload.data[0].messages);
      state.status = 'failed';
      state.isAuthenticated = false;
      if (action.payload) state.error = action.payload.data[0].messages;
      else state.error = action.error.message;
    },
    // Reset
    [authReset.pending]: (state, action) => {
      state.status = 'loading';
      state.isAuthenticated = false;
    },
    [authReset.fulfilled]: (state, action) => {
      state.status = 'succeeded';
      state.isAuthenticated = false;
      state.error = [{ id: 'Auth.newpass.ok' }];
    },
    [authReset.rejected]: (state, action) => {
      state.status = 'failed';
      state.isAuthenticated = false;
      if (action.payload) state.error = action.payload.data[0].messages;
      else state.error = action.error.message;
    },
  },
});

export const { saveToken, saveUser, clearUser, clearStatus, setAuthState } =
  authSlice.actions;
export default authSlice.reducer;
