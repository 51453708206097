import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

// External Actions
// import { setErrorMessage } from '../error/ErrorSlice';

// Helpers
// import auth from '../../helpers/auth';
import { checkResponse } from '../../features/auth/AuthSlice';

// Async Action
export const fetchQuizes = createAsyncThunk(
  'quiz/fetch',
  async (params, { rejectWithValue }) => {
    const response = await fetch(`${process.env.REACT_APP_PROXY_URL}/quizzes`, {
      method: 'GET',
      headers: { 'Content-Type': 'application/json' },
      credentials: 'include',
    })
      // .then((res) => res.json())
      .then(checkResponse)
      .then((data) => {
        if (data.error) return rejectWithValue(data);
        return data;
      })
      .catch((error) => {
        return rejectWithValue(error);
      });

    return response;
  }
);

// Async Action
export const fetchQuizBySlug = createAsyncThunk(
  'quiz/fetchById',
  async (itemId, { rejectWithValue }) => {
    const response = await fetch(
      `${process.env.REACT_APP_PROXY_URL}/quizzes/?slug=${itemId}`,
      {
        method: 'GET',
        headers: { 'Content-Type': 'application/json' },
        credentials: 'include',
      }
    )
      .then((res) => res.json())
      .then((data) => {
        if (data.error) return rejectWithValue(data);
        return data;
      })
      .catch((error) => {
        return rejectWithValue(error);
      });

    return response;
  }
);

// Async Action
export const submitResult = createAsyncThunk(
  'quiz/submitResult',
  async ({ user_id, quiz_id, answers }, { rejectWithValue }) => {
    const response = await fetch(`${process.env.REACT_APP_PROXY_URL}/results`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      credentials: 'include',
      body: JSON.stringify({
        user: user_id,
        quiz: quiz_id,
        answers: answers,
      }),
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.error) rejectWithValue(data);
        return data;
      });

    return response;
  }
);

// Async Action
export const fetchResult = createAsyncThunk(
  'quiz/fetchResult',
  async ({ user_id }, { rejectWithValue }) => {
    const response = await fetch(
      `${process.env.REACT_APP_PROXY_URL}/results?user=${user_id}`,
      {
        method: 'GET',
        headers: { 'Content-Type': 'application/json' },
        credentials: 'include',
      }
    )
      .then((res) => res.json())
      .then((data) => {
        if (data.error) return rejectWithValue(data);
        return data && data[0] ? data[0] : null;
      })
      .catch((error) => {
        return rejectWithValue(error);
      });

    return response;
  }
);

const initialState = {
  items: [],
  item: null,
  item_result: null,
  answers: {},
};

const quizSlice = createSlice({
  name: 'quiz',
  initialState,
  reducers: {
    setAnswer: (state, { payload }) => {
      if (payload) {
        const { question_id, alt_id } = payload;
        state.answers[`${question_id}`] = alt_id;
      }
    },
    // clearToken: (state) => {
    //   state.token = null;
    // },
    // saveUser: (state, { payload }) => {
    //   if (payload) state.user = payload;
    // },
    // clearUser: (state) => {
    //   state.user = null;
    // },
    // setAuthState: (state, { payload }) => {
    //   state.isAuthenticated = payload;
    // },
  },
  extraReducers: {
    // fetchQuizes
    [fetchQuizes.pending]: (state) => {
      state.status = 'loading';
    },
    [fetchQuizes.fulfilled]: (state, action) => {
      state.status = 'succeeded';
      state.items = action.payload;
    },
    [fetchQuizes.rejected]: (state, action) => {
      state.error = action.error.message;
    },
    // fetchQuizBySlug
    [fetchQuizBySlug.pending]: (state) => {
      state.status = 'loading';
    },
    [fetchQuizBySlug.fulfilled]: (state, action) => {
      state.status = 'succeeded';
      state.item = action.payload;
    },
    [fetchQuizBySlug.rejected]: (state, action) => {
      state.error = action.error.message;
    },
    // submitResult
    [submitResult.pending]: (state) => {
      state.status = 'loading';
    },
    [submitResult.fulfilled]: (state, action) => {
      state.status = 'succeeded';
      state.item_result = action.payload;
    },
    [submitResult.rejected]: (state, action) => {
      state.error = action.error.message;
    },
    // fetchResult
    [fetchResult.pending]: (state) => {
      state.status = 'loading';
    },
    [fetchResult.fulfilled]: (state, action) => {
      state.status = 'succeeded';
      state.item_result = action.payload;
    },
    [fetchResult.rejected]: (state, action) => {
      state.error = action.error.message;
    },
  },
});

export const { setAnswer } = quizSlice.actions;
export default quizSlice.reducer;
