import React, { useEffect, Suspense } from 'react';
// import { useSelector, useDispatch } from 'react-redux';
import { Route, BrowserRouter, Switch } from 'react-router-dom';

// CSS
import './App.css';

// Assets
// import img_hero_bg from './assets/test-hero-bg.webp';
// import img_hero_bg_small from './assets/test-hero-bg-small.webp';

// Hooks
// import useImagePreloader from './helpers/useImagePreloader';

// Actions
// import { getCurrentUser } from './features/auth/AuthSlice';

// Components
// import PrivateRoute from './components/PrivateRoute';
const PrivateRoute = React.lazy(() => import('./components/PrivateRoute'));

// Containers
// import ClientApp from './containers/Client';
const ClientApp = React.lazy(() => import('./containers/Client'));
// import ManagerApp from './containers/Manager';
// import AuthRoutes from './containers/Auth';
const AuthRoutes = React.lazy(() => import('./containers/Auth'));

// Preload list
// const preloadSrcList = [img_hero_bg, img_hero_bg_small];

const App = () => {
  // const dispatch = useDispatch();

  // Hooks
  // const { imagesPreloaded, done } = useImagePreloader(preloadSrcList);

  // useEffect(() => {
  //   if (imagesPreloaded) console.log(`Images preloaded: ${done}`);
  // }, [imagesPreloaded, done]);

  return (
    <div className='App'>
      <div id='app'>
        <BrowserRouter basename='/'>
          <div id='app-frame'>
            <Suspense fallback={<div>Loading...</div>}>
              <Switch>
                {/* Old code */}
                {/* <Route
                path='/auth'
                render={(props) => <AuthRoutes {...props} />}
              /> */}

                <PrivateRoute path='/test' component={ClientApp} />
                <Route path='/' render={(props) => <AuthRoutes {...props} />} />
                <Route exact path='' render={(props) => <div>oops!</div>} />
              </Switch>
            </Suspense>
          </div>
        </BrowserRouter>
      </div>
    </div>
  );
};

export default App;
