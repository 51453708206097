import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  mesage: '',
};

const errorSlice = createSlice({
  name: 'error',
  initialState,
  reducers: {
    setErrorMessage: (state, action) => {
      state.message = action.payload;
    },
  },
  extraReducers: {},
});

export const { setErrorMessage } = errorSlice.actions;
export default errorSlice.reducer;
