import { combineReducers } from '@reduxjs/toolkit';
import authReducer from '../features/auth/AuthSlice';
import errorReducer from '../features/error/ErrorSlice';
import quizReducer from '../features/quiz/QuizSlice';

const rootReducer = combineReducers({
  auth: authReducer,
  error: errorReducer,
  quiz: quizReducer,
});

export default rootReducer;
